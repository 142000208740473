import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { theme } from '../../../../layouts/main.layout';

export const titleList = css`
  font-size: ${theme.fontSizes[4]}px;
  margin-top: 30px;
  margin-bottom: 10px;
`;

export const Link = styled.a`
  color: ${theme.colors.primaryTextGray};
  text-decoration: underline;
  &:hover {
    color: ${theme.colors.primaryTextGray};
  }
`;
