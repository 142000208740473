import React from 'react';

import { Flex, Image, Text } from 'rebass';
import { theme } from '../../../layouts/main.layout';

interface ListProps {
  text: string;
}

export const ListItemSquare: React.FC<ListProps> = ({ text }) => {
  return (
    <Flex alignItems="baseline">
      <Image src="/img/square.svg" minWidth="unset" />
      <Text ml={2} fontWeight={theme.fontWeights.extraLight}>
        {text}
      </Text>
    </Flex>
  );
};
